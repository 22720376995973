import * as React from "react";
import { graphql } from "gatsby";
import { replaceCommonStepsSections } from "../common/commonSteps.js"

import Layout from "../components/layout.js";

export default function Page({ pageContext, data })
{
    console.log(pageContext);
    console.log(data);

    const { html } = data.markdownRemark;

    let newHtml = html;

    //common_steps.md may not be in the project
    if(data.allMarkdownRemark.nodes.length){
        const commonStepsHtml = data.allMarkdownRemark.nodes[0].html;
        newHtml = replaceCommonStepsSections(html, commonStepsHtml);
    }

    return (
        <Layout>
            <div dangerouslySetInnerHTML={{__html : newHtml}}/>
        </Layout>
    );
}

export const query = graphql`
query($absolutePath : String!, $commonStepsFilePath : String!) {
    markdownRemark(fileAbsolutePath: {eq: $absolutePath})
    {
        html
    }
    allMarkdownRemark(filter: {fileAbsolutePath: {eq: $commonStepsFilePath}}) {
        nodes {
            html
        }
    }
}
`;
