/*
    Take an array of indexes, e.g. [ 10, 20, 30 ]
    These are the start of each segment
    
    Turn it into an array of segments

    [ [10,20], [20,30] ]
*/
function recursivelyGeneratePairs(locations)
{
    if(locations.length < 2)
    {
        return [];
    }

    const [ first, second, ] = locations;
    const [ _, ...newLocations ] = locations;

    const pair = [ first, second ];

    return [ pair , ...recursivelyGeneratePairs(newLocations)];
}

function parseCommonStepsHtml(commonStepsHtml)
{
    const headerRegex = /<h2>/g;
    const headerLocations = [...commonStepsHtml.matchAll(headerRegex)]
                                .map(x => x.index);

    const segmentLocationsArray = [ ...headerLocations, commonStepsHtml.length ];

    const segmentIndexes = recursivelyGeneratePairs(segmentLocationsArray);

    const segments = segmentIndexes.map( ([start, finish]) => commonStepsHtml.slice(start, finish));

    return segments;
}

function getMatchingCommonStep(commonStepString, commonStepsArray)
{
    const withoutCommonStep = commonStepString.split(/common step[s]?\s?:\s?/ig);

    const [ _, title ] = withoutCommonStep;

    return commonStepsArray.find(x => x.match(title));
}

//This string has 3 parts:
//Before the common steps h2, the common steps h2, after the common steps h2
// before the h2 <h2> Common Steps : </h2> after the h2
function replaceCommonSteps(inputHtml, commonSteps)
{
    const splitRegex = /(<h2>common step[s]?:*.*<\/h2>)/ig;

    const commonStepsSplit = inputHtml.split(splitRegex);

    const commonStepsReplaced = commonStepsSplit.map( section => splitRegex.test(section) ? getMatchingCommonStep(section, commonSteps) : section );

    const newHtml = commonStepsReplaced.join("");

    return newHtml;
}

//Take 2 html pages
//Transform one using data from the other
//Return a third
/*
    * Turn the common steps page into sections
        * Look at the html, an idea will come

    * Replace h2 nodes with a new node
    * Replace the h2 tags in the destination with the actual nodes
    * Return it
*/
function replaceCommonStepsSections(destinationHtml, sourceHtml){
    const commonSteps = parseCommonStepsHtml(sourceHtml);

    const transformedHtml = replaceCommonSteps(destinationHtml, commonSteps);

    return transformedHtml;
}

module.exports = {
    replaceCommonStepsSections
}
