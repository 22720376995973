import React from "react";
import { Link } from "gatsby";

export default function Layout({ children })
{
    return (
        <div>
            <nav>
                <Link to="/"> Home </Link>
            </nav>
            <div>
                { children }
            </div>
        </div>
    );
}
